import DashboardHeader from "../../components/Other/DashboardHeader.jsx";
import { useOutletContext } from "react-router-dom";
import Introduce from "../../components/Introduce/Introduce.jsx";
import Quote from "../../components/Quote/Quote.jsx";
import Project from "../../components/Projects/Project.jsx"; // Add this line
import AboutMe from "../../components/AboutMe/AboutMe.jsx";
import Skill from "../../components/Skill/Skill.jsx";
import Footer from "../../components/Footer.jsx";
import ContactMe from "../../components/ContactMe/ContactMe.jsx";
import { useRef, useState } from "react";

function Dashboard() {

  const [sidebarToggle] = useOutletContext();
  const [activeSection, setActiveSection] = useState('');
  const refs = {
    introduceRef: useRef(null),
    quoteRef: useRef(null),
    projectRef: useRef(null),
    skillRef: useRef(null),
    aboutmeRef: useRef(null),
    contactmeRef: useRef(null),
  };

  const handleScrollToSection = (section) => {
    const targetSection = refs[section].current;
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      setActiveSection(section);
    }
  };
    

  return (
    <>
      <main className="h-full mr-auto ml-auto container main-background">
        {/* Welcome Header */}
        <DashboardHeader
          toggle={sidebarToggle}
          handleScrollToSection={handleScrollToSection}
        />

        {/* Laba */}

        <div className="px-2 mt-16 mx-auto mainCard">
          <div className="w-full">
            <Introduce sectionRef={refs.introduceRef} activeSection={activeSection}/>
            <Quote sectionRef={refs.quoteRef} activeSection={activeSection}/>
            <Project sectionRef={refs.projectRef} activeSection={activeSection}/> {/* Fixed the undefined reference */}
            <Skill sectionRef={refs.skillRef} activeSection={activeSection}/>
            <AboutMe sectionRef={refs.aboutmeRef} activeSection={activeSection}/>
            <ContactMe sectionRef={refs.contactmeRef} activeSection={activeSection}/>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default Dashboard;
