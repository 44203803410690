import React from "react";

function Footer() {
  return (
    <footer className="py-3 px-6 flex items-center border-t active-text justify-center">
      <div className="flex-1 text-center">&copy; {new Date().getFullYear()} DauLe. Allright Reserved</div>
    </footer>
  );
}

export default Footer;
