import React, { useEffect } from 'react';
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import './index.css';
import MenuItem from "./MenuItem";

const menus = [
  {
    url: '#home',
    name: 'home',
    refName: 'introduceRef'
  },
  {
    url: '#projects',
    name: 'projects',
    refName: 'projectRef'
  },
  {
    url: '#skills',
    name: 'skills',
    refName: 'skillRef'
  },
  {
    url: '#about-me',
    name: 'about-me',
    refName: 'aboutmeRef'
  },
  {
    url: '#contact',
    name: 'contacts',
    refName: 'contactmeRef'
  }
]

function DashboardHeader({ handleScrollToSection }) {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const generateMenu = (menus, menuState) => {
    return (
      <>
        {
          menus.map((menu, index) => (
              <MenuItem 
                url={menu.url} 
                key={index} 
                refName={menu.refName} name={menu.name} 
                handleScrollToSection={handleScrollToSection}
              /> 
          ))
        }
      </>
    )
  }
  return (
    <>
      <Disclosure as="nav" className={isMobile ? 'fixed w-full bg-[#282C33] z-[99999]' : 'w-full bg-[#282C33] z-[99999]'} >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button 
                  className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex w-full items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex sm:w-1/3 flex-shrink-0 items-center">
                  <div id="nameSection" className="flex flex-row logo">
                    <img src="./logo192.svg" alt="Login" />
                    <label className="active-text">Dau Le</label>
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:w-2/3 sm:block ">
                  <div className="flex space-x-4 sm:justify-end">
                    
                    {generateMenu(menus)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden fixed w-full h-dvh bg-[#282C33]">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {generateMenu(menus)}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    </>
    
  );
}

export default DashboardHeader;
