import React from 'react'; // Import the 'HeaderSection' component
import SectionHeader from '../Other/SectionHeader/SectionHeader';
import './index.css';

const ContactMe = ({ sectionRef, activeSection }) => {
  return (
    <div className={`w-full flex flex-col mt-3 ${activeSection ? 'pt-3': ''}`} ref={sectionRef}>
      <SectionHeader showViewMore={false}  headerContent="contact-me" />
      <div className="w-full flex sm:flex-row flex-col gray-text mb-4">
        <div className='sm:w-3/4 w-full sm:p-5 p-5 py-2'>
          <p className="mt-6 sm:w-3/4 w-full text-justify">
            I’m interested in freelance opportunities. However, if you have other request or question, don’t hesitate to contact me
          </p>
        </div>

        <div className='sm:w-1/3 w-full sm:p-5 p-5 py-2'>
          <h1 className="text-2xl active-text sm:mt-6 mt-2 mb-2">Message Me</h1>
          <div className='w-full flex flex-row items-center'>
            <img src="./images/Phone_fill.svg" alt="." width="32" className='mr-2' /> (+84) 392 138 149
          </div>
          <div className='w-full flex flex-row items-center'>
            <img src="./images/Email.svg" alt="." width="32" className='mr-2'/><a href='mailto:lqdaucntt@gmail.com'>lqdaucntt@gmail.com</a>
          </div>
          <div className='w-full flex flex-row items-center'>
            <img src="./images/github.svg" alt="." width="32" className='mr-2'/><a href='https://github.com/dauleit' target="_blank" rel="noreferrer">dauleit</a>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ContactMe;