import React, { useEffect } from 'react';
import './index.css';
import { Link } from 'react-router-dom';

const SectionHeader = ({ showViewMore, headerContent, url }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={`w-full flex flex-row ${isMobile ? 'section-header-mobile': 'section-header'}`}>
      <h1 className="text-2xl w-1/2 active-text"><span className="header-text"><span className='secondary-text'>#</span>{headerContent}</span></h1>
      <div className={`w-1/2 ${isMobile ? 'hidden' : ''} flex flex-row justify-end pr-2 active-text items-center`}>
        {showViewMore && <p><Link to={url}>{`View More ~~>`}</Link></p>}
      </div>
    </div>
  )
}


export default SectionHeader;