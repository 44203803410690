import React from 'react'; // Import the 'HeaderSection' component
import SectionHeader from '../Other/SectionHeader/SectionHeader';
import './index.css';
import { Link } from 'react-router-dom';

const AboutMe = ({ sectionRef, activeSection }) => {
  return (
    <div className={`w-full flex flex-col mt-3 ${activeSection ? 'pt-16': ''}`} ref={sectionRef}>
      <SectionHeader showViewMore={false}  headerContent="about-me" />
      <div className="w-full flex flex-row gray-text content-box">
        <div className='sm:w-1/2 w-full p-5'>
          <p className="mt-6 text-justify">
            Hello, I'm DauLe (Mr.D). <br /><br />
            I'm <span className="secondary-text">Fullstack website developer</span> and <span className="secondary-text">Project Manager</span> with 9 years experiences. <br/>
            I'm currently based in Da Nang, Vietnam. Welcome to my website! This is a place where I share my projects and experiences. <br />
            I'm a full stack web developer with experience in building websites and web applications. I specialize in JavaScript and have professional experience working with <span className="secondary-text">React, Node.js, ExpressJS, AdonisJS and Nestjs</span>. I also have experience working with databases such as <span className="secondary-text">MySQL and MongoDB, Redis, ElasticSearch .etc</span>. I also  work on the most of <span className="secondary-text">AWS services, Mobile and Blockchain. </span><br /><br />
            I'm passionate about learning new technologies and building projects that help solve real-world problems. I'm currently looking for new opportunities to work on exciting projects and collaborate with other developers. <br />
            If you are interested in working with me, please feel free to contact me. <br /><br />
            Thank you for visiting my website!
          </p>
          <Link to='/#contact' className="pr-4 pl-4 pt-2 pb-2 mt-6 border-secondary inline-block active-text">Contact Me!</Link>
        </div>
      </div>
      
    </div>
  );
};

export default AboutMe;