import React from 'react';
import SectionHeader from '../Other/SectionHeader/SectionHeader';
import ProgressBar from "@ramonak/react-progress-bar";

const Skill = ({ sectionRef, activeSection }) => {
  const skills = [
    { name: 'Reactjs', logo: '/images/react-logo.png', progress: 72 },
    { name: 'Nestjs', logo: '/images/nestjs-logo.png', progress: 85 },
    { name: 'Mongodb', logo: '/images/mongodb-logo.png', progress: 84 },
    { name: 'Mysql', logo: '/images/mysql-logo.png', progress: 78 },
    { name: 'Postgres', logo: '/images/postgres-logo.png', progress: 65 },
    { name: 'Redis', logo: '/images/redis-logo.png', progress: 78 },
    { name: 'Socket.io', logo: '/images/socketio-logo.png', progress: 87 },
    { name: 'AWS', logo: '/images/aws-logo.png', progress: 76 },
  ];

  const others = [
    { name: 'Elasticsearch', logo: '/images/elasticsearch-logo.png', progress: 50 },
    { name: 'PHP', logo: '/images/php-logo.png', progress: 56 },
    { name: 'Solidity', logo: '/images/solidity-logo.png', progress: 30 },
    { name: 'NGINX', logo: '/images/nginx-logo.png', progress: 67 },
    { name: 'Kibana', logo: '/images/kibana-logo.png', progress: 25 },
  ];

  return (
    <div className={`w-full mt-6 ${activeSection ? 'pt-16': ''}`} ref={sectionRef}>
      <SectionHeader headerContent='Skills' showViewMore={false} url='#' />
      {skills.map((skill) => (
        <div key={skill.name} className="w-full flex flex-row items-center mt-3">
          <div className='sm:w-1/12 xs:w-2/12'>
            <img src={skill.logo} alt={skill.name} className="sm:w-16 w-14 sm:p-0 p-2" />
          </div>
          <ProgressBar completed={skill.progress} bgColor="#C778DD" className="w-9/12" baseBgColor="#d1d5db" height="5px" isLabelVisible={false} />
        </div>
      ))}
      <h1 className="text-2xl mt-8 active-text">Others</h1>

      {others.map((skill) => (
        <div key={skill.name} className="w-full flex flex-row items-center mt-3">
          <div className='sm:w-1/12 xs:w-2/12'>
            <img src={skill.logo} alt={skill.name} className="sm:w-16 w-14 sm:p-0 p-2" />
          </div>
          <ProgressBar completed={skill.progress} bgColor="#C778DD" className="w-9/12" baseBgColor="#d1d5db" height="5px" isLabelVisible={false} />
        </div>
      ))}
    </div>
  );
};


export default Skill;