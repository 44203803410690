import React from "react";
import { Outlet } from "react-router-dom";
import { sidebarToggle } from "./../../utils/toggler.js";

function AuthLayout({ ...props }) {

  return (
    <div className="adminLayout">

      {/* Main Wrapper */}
      <div className="mainWrapper">
        <Outlet context={[sidebarToggle]} />
      </div>
    </div>
  );
}

export default AuthLayout;
