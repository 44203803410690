import React from "react";
import './index.css';

export default  function Quote({ sectionRef, activeSection }) {
  return (
    <div className={`quote w-full sm:p-0 p-2 flex flex-row mt-16 ${activeSection === 'quoteRef' ? 'pt-16': ''} justify-center`} ref={sectionRef}>
      <div className="sm:w-1/2 xs:w-full border-solid p-5 pt-8 pb-8 relative">
        <img src="./images/Quote-icon.svg" className="first-quote" alt="icon"/>
        <p className="active-text sm:text-2xl xs:text-xl">
        Even if you stumble in your attempts, surrendering guarantees your defeat.
        </p>
        <div className="text-right gray-text italic">
          Mr.D
        </div>
        <img src="./images/Quote-icon.svg" className="last-quote" alt="icon"/>
      </div>
    </div>
  )

}