import React, { useEffect } from 'react';
import ProjectItem from './ProjectItem/ProjectItem.jsx';
import SectionHeader from '../Other/SectionHeader/SectionHeader.jsx';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';
const projects = [
    {
        cover: 'https://via.placeholder.com/240x120',
        name: 'Project 1',
        description: 'This is project 1',
        techStack: ['React', 'Node.js', 'Express'],
        enabled: true,
    },
    {
        cover: 'https://via.placeholder.com/240x120',
        name: 'Project 2',
        description: 'This is project 2',
        techStack: ['React', 'Node.js', 'Express'],
        enabled: false,
    },
    {
        cover: 'https://via.placeholder.com/240x120',
        name: 'Project 3',
        description: 'This is project 3',
        techStack: ['React', 'Node.js', 'Express'],
        enabled: true,
    },
    {
        cover: 'https://via.placeholder.com/240x120',
        name: 'Project 4',
        description: 'This is project 4',
        techStack: ['React', 'Node.js', 'Express'],
        enabled: false,
    },
    {
        cover: 'https://via.placeholder.com/240x120',
        name: 'Project 5',
        description: 'This is project 5',
        techStack: ['React', 'Node.js', 'Express'],
        enabled: true,
    },
    {
        cover: 'https://via.placeholder.com/240x120',
        name: 'Project 6',
        description: 'This is project 6',
        techStack: ['React', 'Node.js', 'Express'],
        enabled: false,
    }
]
function Project({ sectionRef, activeSection }) {
    const [isMobile, setIsMobile] = React.useState(false);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 640);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Check on initial render

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    const projectItems = Array.from(projects, (project, i) => <ProjectItem project={project} key={i} />);
    
    const itemsPerRow = 3;
    const length = isMobile ? (projectItems.length - 3) : projectItems.length;
    console.log(length);
    const rows = Array.from({ length: Math.ceil(length / itemsPerRow) }, (_, i) => {
        const rowItems = projectItems.slice(i * itemsPerRow, (i * itemsPerRow) + itemsPerRow);
        return (
            <div key={i} className="flex sm:flex-row flex-col">
                {rowItems.map((item, index) => (
                    <div key={index} className="sm:w-1/3 xs:w-full">
                        {item}
                    </div>
                ))}
            </div>
        );
    });
    
    return (
        <div ref={sectionRef} className={activeSection ? 'pt-4': ''}>
            <Element className="w-full mt-12 element justify-end" name="projects" >
                <SectionHeader url={'/projects'} showViewMore={true} headerContent={"projects"} />
                {rows}
                <Link to='/#contact' className="pr-4 pl-4 ml-2 pt-2 pb-2 mt-4 border-secondary inline-block active-text">{`View More ~~>`}</Link>
            </Element>
        </div>
        
    );
};

export default Project;
