import React from "react";
import { Link } from "react-router-dom";
import './index.css';

export default  function Introduce({ sectionRef, activeSection }) {
  console.log(activeSection)
  return (
    <div className={`introduce w-full sm:p-0 p-2 flex sm:flex-row flex-col ${activeSection === 'introduceRef' ? 'pt-16': ''}`} ref={sectionRef}>
      <div className="xs:w-full sm:w-1/2">
        <h1 className="active-text text-2xl">
          I'm <label className="secondary-text">Project Manager </label>and <label className="secondary-text">Fullstack website developer</label> 
        </h1>
        <p className="gray-text mt-8">
          Hi! I'm a software engineer specializing in website and mobile development, I also manage project with 5 years experiences. My name is Dau Le  and I am currently based in Da Nang. 
        </p>
        <Link to='/#contact' className="pr-4 pl-4 pt-2 pb-2 mt-6 border-secondary inline-block active-text">Contact Me!</Link>
      </div>
      <div className="xs:w-full sm:w-1/2 relative avatar-content sm:block hidden">
        <img src="./images/introduce-background.svg" className="ml-5" alt="."/>
        <img src="./images/Avatar.png" className="avatar" alt="."/>
        <img src="./images/Dots.svg" className="dots" alt="."/>
        <div className="current">
          <img src="./images/Rectangle.svg" alt="." className="mr-2" />
          Currently working on <b>&nbsp; Napa Global</b>
        </div>
      </div>
    </div>
  )

}