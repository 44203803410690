import React from 'react';

const ProjectItem = ({ project }) => {
  const generateTechStack = (techStack) => {
    return techStack.map((tech, index) => {
      return (
        <div key={index} className="ml-2 mr-2 gray-text">
          {tech}
        </div>
      );
    });
  }
  return (
    <div className="flex flex-col w-full p-2 ">
        
        {/* Image */}
        
        <div className="w-full border border-gray-100">
          {/* Add your image component here */}
          <img src={project.cover} alt="Project cover" className="w-full" />
        </div>

        <div className="w-full flex flex-row border border-gray-100 py-4">
          { generateTechStack(project.techStack)}
        </div>

        {/* Left Component */}
        <div className="w-full border border-gray-100 p-4 ">
          {/* Project Name */}
          <p className="text-2xl active-text mb-4">{project.name}</p>
          {/* Add your project name component here */}

          {/* Description */}
          <p className="text-xl gray-text mb-4">{project.description}</p>
          {/* Add your description component here */}

          {
            /* Enable Button */
            /* Add your enable button component here */
          }
          {project.enabled ? <button className="border border-pink-400 py-2 px-4 active-text">Live</button> : <button className="border border-gray-300 px-4 py-2 gray-text">Cached</button>}
        </div>
      </div>
  );
};

export default ProjectItem;