import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function MenuItem({ name, url, refName, handleScrollToSection, handleOnclick }) {
  const [currentPath, setCurrentPath] = useState('');
  const location = useLocation();
  useEffect(() => {
    setCurrentPath(location.hash)
  }, [location]);
  return (
    <div className="menu-item">
      <Link to={url} onClick={() => {
        handleScrollToSection(refName);
      }}>
          <Disclosure.Button>
          <label className="secondary-text">#</label>
          <label className={`${currentPath}` === url ? 'active-text' : 'gray-text'}>{name}</label>
        </Disclosure.Button>
      </Link>
    </div>
  )
}